import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'

import EventList from '@/views/EventList'
import EventDetails from '@/views/event/Details'
import EventCreate from '@/views/event/Create'
import About from '@/views/About'
import ErrorDisplay from '@/views/errors/ErrorDisplay'
import NotFound from '@/views/errors/NotFound'
import NetworkError from '@/views/errors/NetworkError'

const routes = [
  {
    path: '/',
    name: 'EventList',
    component: EventList,
    props: (route) => ({
      page: parseInt(route.query.page) || 1,
    }),
  },
  {
    path: '/events/:id',
    name: 'EventDetails',
    component: EventDetails,
    props: true,
  },
  {
    path: '/events/create',
    name: 'EventCreate',
    component: EventCreate,
  },
  {
    path: '/about-us',
    name: 'About',
    component: About,
    /*  when the user visits '/about', the URL remains '/about', but it will be matched
        as if the user is visiting '/about--us'.
    alias: '/about',
     */
  },

  // Redirects

  {
    path: '/about',
    redirect: { name: 'About' },
  },
  {
    path: '/event/:afterEvent(.*)',
    redirect: (to) => ({ path: `/events/${to.params.afterEvent}` }),

    /* another approach of redirecting nested routes
    path: '/event/:id',
    redirect: () => ({ name: 'EventDetails' }),
    children: [
      {
        path: 'edit',
        redirect: () => ({ name: 'EventEdit' }),
      },
      {
        path: 'register',
        redirect: () => ({ name: 'EventRegister' }),
      },
    ],
     */
  },

  // Catch invalid page requests

  {
    path: '/error/:error',
    name: 'ErrorDisplay',
    component: ErrorDisplay,
    props: true,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/404/:resource',
    name: '404Resource',
    component: NotFound,
    props: true,
  },
  {
    path: '/network-error',
    name: 'NetworkError',
    component: NetworkError,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { top: 0 }
  },
})

router.beforeEach((to, from) => {
  NProgress.start()

  const notAuthorized = true
  if (to.meta.requireAuth && notAuthorized) {
    return from.href ? false : { path: '/' }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
