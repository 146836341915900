<template>
  <h1>Events For Good</h1>
  <div class="events">
    <EventCard v-for="event in shownEvents" :key="event.id" :event="event" />
  </div>
  <div class="pagination">
    <router-link
      id="page-prev"
      :to="{ name: 'EventList', query: { page: page - 1 } }"
      rel="prev"
      v-if="page !== 1"
    >
      &laquo;
    </router-link>
    <span class="page-numbers">
      <router-link
        v-for="num in totalPages"
        :key="num"
        :to="{ name: 'EventList', query: { page: num } }"
        :class="num === page && 'active'"
      >
        {{ num }}
      </router-link>
    </span>
    <router-link
      id="page-next"
      :to="{ name: 'EventList', query: { page: page + 1 } }"
      rel="next"
      v-if="hasNextPage"
    >
      &raquo;
    </router-link>
  </div>
</template>

<script>
import EventCard from '@/components/EventCard'

export default {
  name: 'EventList',
  components: {
    EventCard,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    eventsPerPage: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    allEvents() {
      return this.$store.state.events
    },
    shownEvents() {
      const fromIdx = Math.max(0, this.eventsPerPage * (this.page - 1))
      const toIdx = Math.min(this.totalEvents, this.eventsPerPage * this.page)
      return this.$store.state.events.slice(fromIdx, toIdx)
    },
    totalEvents() {
      return this.$store.getters.totalEvents
    },
    totalPages() {
      return Math.ceil(this.totalEvents / this.eventsPerPage)
    },
    hasNextPage() {
      return this.page < this.totalPages
    },
  },
  created() {
    this.$store.dispatch('fetchAllEvents').catch((error) => {
      this.$router.push({ name: 'ErrorDisplay', params: { error: error } })
    })
  },
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pagination {
  display: inline-block;
  width: 290px;
  text-align: center;
}

.pagination a {
  text-decoration: none;
  color: #2c3e50;
  padding: 8px 16px;
}

.pagination a.active {
  color: white;
  background-color: #42b983;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  color: #42b983;
}

#page-prev {
  text-align: left;
}

#page-next {
  text-align: right;
}
</style>
